// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-defaultpage-jsx": () => import("./../src/templates/defaultpage.jsx" /* webpackChunkName: "component---src-templates-defaultpage-jsx" */),
  "component---src-templates-five-ch-post-jsx": () => import("./../src/templates/five_ch-post.jsx" /* webpackChunkName: "component---src-templates-five-ch-post-jsx" */),
  "component---src-templates-movie-post-jsx": () => import("./../src/templates/movie-post.jsx" /* webpackChunkName: "component---src-templates-movie-post-jsx" */),
  "component---src-templates-tag-post-jsx": () => import("./../src/templates/tag-post.jsx" /* webpackChunkName: "component---src-templates-tag-post-jsx" */),
  "component---src-templates-twitter-post-jsx": () => import("./../src/templates/twitter-post.jsx" /* webpackChunkName: "component---src-templates-twitter-post-jsx" */)
}

