import React from "react"

const defaultState = {
  dark: false,
  toggleDark: () => {},
  tabNumber: 0
}

const ThemeContext = React.createContext(defaultState)

// Getting dark mode information from OS!
// You need macOS Mojave + Safari Technology Preview Release 68 to test this currently.
const supportsDarkMode = () =>
  window.matchMedia("(prefers-color-scheme: dark)").matches === true

class ThemeProvider extends React.Component {
  state = {
    dark: false,
    quoteSource:'all',
    tabNumber: 0
  }

  toggleDark = () => {
    let dark = !this.state.dark
    localStorage.setItem("dark", JSON.stringify(dark))
    this.setState({ dark:dark })
  }

  changeGenre = (quoteSource) => {
    localStorage.setItem("quoteSource", JSON.stringify(quoteSource))
    this.setState({ quoteSource:quoteSource })
  }

  changeTab = (tabNumber) => {
    localStorage.setItem("tabNumber", JSON.stringify(tabNumber))
    this.setState({ tabNumber:tabNumber })
  }

  componentDidMount() {
    // Getting dark mode value from localStorage!
    const lsDark = JSON.parse(localStorage.getItem("dark"))
    if (lsDark) {
      this.setState({ dark: lsDark })
    } else if (supportsDarkMode()) {
      this.setState({ dark: true })
    }
  }

  render() {
    const { children } = this.props
    const { dark, quoteSource, tabNumber} = this.state
    return (
      <ThemeContext.Provider
        value={{
          dark,
          quoteSource,
          tabNumber,
          toggleDark: this.toggleDark,
          changeGenre: this.changeGenre,
          changeTab: this.changeTab
        }}
      >
        {children}
      </ThemeContext.Provider>
    )
  }
}

export default ThemeContext

export { ThemeProvider }